import type { NextPage } from 'next';
import Section from '@/components/Section';
import Head from 'next/head';
import { ExternalLinkIcon } from '@heroicons/react/solid';

export const SellSection = () => {
    return (
        <Section>
            <h2 className="pt-4 pb-2 text-xl font-extrabold tracking-tight text-gray-900">
                Sell
            </h2>
            <p>Ready to monetize your audience?</p>
            <a
                href="https://www.virra.com/schedule"
                className="inline-flex items-center rounded-md bg-gray-100 px-8 py-4 text-sm font-medium text-gray-800 hover:bg-gray-200"
            >
                Schedule a call
                <ExternalLinkIcon className="ml-1 h-5 w-5" />
            </a>
            {/* <div className="rounded-lg bg-gray-50 p-4 py-20 text-center text-lg">
                <p className="text-gray-400">Coming Soon…</p>
            </div> */}
            {/* <div className="grid grid-cols-2 gap-4">
                {navigation.map((navItem) => (
                    <ServiceCard
                        key={navItem.href}
                        href={navItem.href}
                        bgStyles={classNames(
                            navItem.bgStyles,
                            'aspect-[1/1] rounded-lg group'
                        )}
                        text={navItem.text}
                        icon={{
                            Icon: navItem.icon,
                            shadow: navItem.shadow,
                        }}
                    />
                ))}
            </div> */}
        </Section>
    );
};

const Sell: NextPage = () => {
    return (
        <div>
            <Head>
                <title>Sell | Virra</title>
                <link rel="icon" href="/favicon.ico" />
            </Head>

            <main>
                {/* <SubNavigation navigation={navigation} /> */}
                <SellSection />
            </main>
        </div>
    );
};

export default Sell;
