import type { NavigationProps } from '@/components/Navigation';
import type { AuthProps } from 'types/AuthProps';
import Section from '@/components/Section';
import ServiceCard from '@/components/ServiceCard';
import SubNavigation from '@/components/SubNavigation';
import { useAuth } from '@/lib/auth';
import { UsersIcon, ChartBarIcon, TableIcon } from '@heroicons/react/solid';
import Head from 'next/head';
import SVGIcon from '@/components/IconHandler';

const pageIsAdminOnly = true;

export function getReportsNavigation(auth: AuthProps) {
    return auth.user.isAdmin
        ? navigation
        : navigation.filter((navItem) => !navItem.isAdmin);
}

const navigation: NavigationProps[] = [
    {
        text: 'Users',
        href: '/reports/users',
        Icon: SVGIcon(UsersIcon),
        isAdmin: true,
        bgStyles:
            "bg-[url('/assets/images/superfan-unique-emails.png')] aspect-[4/6] text-white",
    },
    {
        text: 'Requests', // Software tool requests
        href: '/reports/requests',
        Icon: SVGIcon(TableIcon),
        isAdmin: true,
        // linkStyles: 'col-span-2',
        bgStyles:
            "bg-[url('/assets/images/requests.png')] aspect-[4/6] text-white",
    },
    {
        text: 'Tracking', // Software tool requests
        href: '/reports/tracking',
        Icon: SVGIcon(ChartBarIcon),
        isAdmin: true,
        linkStyles: 'col-span-2',
        bgStyles:
            "bg-[url('/assets/images/tracking.png')] aspect-[1/0.3] text-white",
    },
    // {
    //     text: 'History', // Software tool requests
    //     href: '/reports/history',
    //     Icon: SVGIcon(RewindIcon),
    //     linkStyles: 'col-span-2',
    //     bgStyles:
    //         "bg-[url('/assets/images/superfan-unique-emails.png')] aspect-[1/0.3] text-white",
    // },
];

export const ReportsSection = () => {
    const auth = useAuth();
    return (
        <Section>
            <h2 className="pt-4 pb-2 text-xl font-extrabold tracking-tight text-gray-900">
                Reports
            </h2>
            <div className="grid grid-cols-2 gap-4">
                {getReportsNavigation(auth).map((navItem) => {
                    // only show admin cards if user is admin

                    return (
                        <ServiceCard
                            key={navItem.href}
                            href={navItem.href}
                            text={navItem.text}
                            bgStyles={navItem.bgStyles ? navItem.bgStyles : ''}
                            linkStyles={navItem.linkStyles}
                        />
                    );
                })}
            </div>
        </Section>
    );
};

const Reports = () => {
    const auth = useAuth();
    return (
        <div>
            <Head>
                <title>Reports | Virra Admin</title>
                <meta name="description" content="" />
                <link rel="icon" href="/favicon.ico" />
            </Head>

            <main>
                <SubNavigation navigation={getReportsNavigation(auth)} />
                <ReportsSection />
            </main>
        </div>
    );
};

export default Reports;

Reports.isAdminOnly = pageIsAdminOnly;
