import type { NavigationProps } from '@/components/Navigation';
import type { AuthProps } from 'types/AuthProps';
import type { NextPage } from 'next';
import Section from '@/components/Section';
import ServiceCard from '@/components/ServiceCard';
import SubNavigation from '@/components/SubNavigation';
import { useAuth } from '@/lib/auth';
import { BellIcon, CollectionIcon } from '@heroicons/react/solid';
import Head from 'next/head';
import SVGIcon from '@/components/IconHandler';

export function getConnectNavigation(auth: AuthProps) {
    return auth.user.isAdmin
        ? navigation
        : navigation.filter((navItem) => !navItem.isAdmin);
}

const navigation: NavigationProps[] = [
    {
        text: 'Match',
        href: '/connect/match',
        Icon: SVGIcon(CollectionIcon),
        bgStyles:
            "bg-[url('/assets/images/playlist-search.png')] aspect-[4/6] text-white",
    },
    {
        text: 'Superfans',
        href: '/connect/superfans',
        Icon: SVGIcon(BellIcon),
        bgStyles:
            "bg-[url('/assets/images/playlist-searches.png')] aspect-[4/6] text-white",
    },
];

export const ConnectSection = () => {
    const auth = useAuth();
    return (
        <Section>
            <h2 className="pt-4 pb-2 text-xl font-extrabold tracking-tight text-gray-900">
                Connect
            </h2>
            <div className="grid grid-cols-2 gap-4">
                {getConnectNavigation(auth).map((navItem) => {
                    // only show admin cards if user is admin

                    return (
                        <ServiceCard
                            key={navItem.href}
                            href={navItem.href}
                            text={navItem.text}
                            bgStyles={navItem.bgStyles ? navItem.bgStyles : ''}
                            linkStyles={navItem.linkStyles}
                        />
                    );
                })}
            </div>
        </Section>
    );
};

const Connect: NextPage = () => {
    const auth = useAuth();
    return (
        <div>
            <Head>
                <title>Connect | Virra</title>
                <link rel="icon" href="/favicon.ico" />
            </Head>

            <main>
                <SubNavigation navigation={getConnectNavigation(auth)} />
                <ConnectSection />
            </main>
        </div>
    );
};

export default Connect;
